import React, { useState, useMemo, useEffect } from "react";
import { CpButton, CpLoader, CpOverlay } from "canopy-styleguide!sofe";
import { Selection } from "../contact-list.component";
import useContactsListQuery from "../use-contacts-list-query.hook";
import {
  MergeConflictModal,
  MergeConflictModalReturnData,
} from "./merge-conflict-modal/merge-conflict-modal.component";

type MergeContactsProps = {
  selection: Selection;
  onClose: () => void;
};

export function MergeContacts({ selection, onClose }: MergeContactsProps) {
  const [show, setShow] = useState(true);
  const closeOverlay = () => setShow(false);
  const [showConflictModal, setShowConflictModal] = useState(false);
  const selectedContactIds = useMemo(() => selection.toArray(), [selection]);
  const { contacts, contactsQuery } = useContactsListQuery({
    page: 1,
    limit: "10",
    infiniteScroll: false,
    filters: { id: [{ values: selectedContactIds }] },
    sortData: [],
    search: "",
    visibleFields: [
      "cp_user_invited",
      /* TODO: add "qbo_connected",*/ "primary_email",
      "primary_phone",
      "primary_address",
      "name",
    ],
    disabled: false,
  });

  const onCloseConflictModal = (returnData?: MergeConflictModalReturnData) => {
    setShowConflictModal(false);
    if (returnData) {
      // TODO: Exclude returned contacts from the list
    } else {
      closeOverlay();
    }
  };

  const loading = contactsQuery.isLoading;
  const contactsWithCPLogin = useMemo(() => contacts.filter((contact) => contact.cp_user_invited), [contacts]);
  const contactsWithQBOConnected = useMemo(() => contacts.filter((contact) => contact.qbo_connected), [contacts]);

  const hasCpLoginConflict = contactsWithCPLogin.length > 1;
  const hasQBOConflict = contactsWithQBOConnected.length > 1;

  useEffect(() => {
    if (hasCpLoginConflict || hasQBOConflict) {
      setShowConflictModal(true);
    }
  }, [hasCpLoginConflict, hasQBOConflict]);

  /**
   * TODO:
   * exclude returned contacts from the conflict modal from the list and display the remaining contacts
   */

  return (
    <CpOverlay show={show} onClose={closeOverlay} onAfterClose={onClose}>
      <CpOverlay.Header title="Merge Contacts">
        <CpButton onClick={closeOverlay}>Continue</CpButton>
      </CpOverlay.Header>
      <CpOverlay.Body>
        {loading || hasCpLoginConflict || hasQBOConflict ? <CpLoader /> : <div>// TODO: Display contact tiles</div>}
      </CpOverlay.Body>
      {showConflictModal && (
        <MergeConflictModal
          cpConflictContacts={contactsWithCPLogin}
          qboConflictContacts={contactsWithQBOConnected}
          onClose={onCloseConflictModal}
        />
      )}
    </CpOverlay>
  );
}
